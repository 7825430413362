<template>
    <div class="access-roles-field">
        <v-autocomplete
            v-model="selectedMemberId"
            :items="foundMembers"
            :loading="isSearchingMember"
            :search-input.sync="searchMember"
            outlined
            hide-no-data
            hide-selected
            hide-details
            clearable
            item-text="member_email"
            item-value="member_id"
            :label="$trans('Email')"
            :placeholder="$trans('Start typing to Search')"
            @click:clear="foundMembers = []"
        >
            <template v-slot:item="{ item, attrs, on }">
                <v-list-item dense v-bind="attrs" v-on="on" class="my-2">
                    <v-list-item-content>
                        <v-list-item-title>{{ item['member_email'] }}</v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item['member_name'] }} ({{ item['member_hint'] }})
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
        <v-radio-group
            v-if="roles"
            v-model="inputRole"
            :disabled="!selectedMemberId"
            @change="onInput"
        >
            <v-radio
                v-for="(item, key) in rolesComputed"
                :key="key"
                :value="key"
            >
                <template v-slot:label>
                    <div>
                        <div>{{ item.label }}</div>
                        <div class="caption">{{ item.hint }}</div>
                        <v-divider class="my-3"/>
                    </div>
                </template>
            </v-radio>
        </v-radio-group>
    </div>
</template>

<script>

import { fetchAccessMemberCollection } from '@apps/employee/api/access'
import hasCurrentSchoolPropertyMixin from '@apps/school/mixins/hasCurrentSchoolPropertyMixin'
import Debugger from '@/utils/Debugger'

export default {
    name: 'AccessRolesField',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        role: {
            type: String,
            default: null
        },
        memberId: {
            type: String,
            default: null
        },
        memberType: {
            type: String,
            default: null
        },
        roles: {
            type: Object,
            required: true
        }
    },
    mixins: [
        hasCurrentSchoolPropertyMixin
    ],
    computed: {
        rolesComputed() {
            if(this.$auth.isSuperAdmin()) {
                return this.roles
            }

            const roles = {}

            for (const role in this.roles) {
                if(role !== 'admin') {
                    roles[role] = this.roles[role]
                }
            }

            return roles
        },
        selectedMemberType() {
            if (!this.selectedMemberId) {
                return null
            }

            const member = this.foundMembers.find(o => o.member_id === this.selectedMemberId)

            if (!member) {
                return null
            }

            return member.member_type
        }
    },
    data() {
        return {
            inputRole: null,
            inputEmail: null,
            isSearchingMember: false,
            searchMember: null,
            selectedMemberId: null,
            foundMembers: []
        }
    },
    watch: {
        searchMember(val) {
            if (!val || (val && val.length < 2)) {
                this.isSearchingMember = false
                return
            }

            // Items have already been loaded
            if (this.foundMembers.length > 0) return

            // Items have already been requested
            if (this.isSearchingMember) return

            this.isSearchingMember = true

            // Lazily load input items
            setTimeout(() => {
                fetchAccessMemberCollection(val)
                    .then(response => {
                        this.foundMembers = response.data.data.filter(o => {
                            if(o.member_type === 'student') {
                                return false
                            }

                            if(this.$auth.isSuperAdmin()) {
                                return true
                            }

                            if(!o.member_relations.school) {
                                return true
                            }

                            return o.member_relations.school.uuid === this.currentSchool.uuid
                        })
                    })
                    .catch(err => {
                        Debugger.log(err)
                    })
                    .finally(() => (this.isSearchingMember = false))
            }, 300)
        }
    },
    methods: {
        onInput() {
            this.$emit('input', {
                target: 'resource',
                role: this.inputRole,
                memberId: this.selectedMemberId,
                memberType: this.selectedMemberType
            })
        }
    }
}
</script>

<style lang=scss>
.access-roles-field {
    .v-radio {
        align-items: flex-start !important;
    }
}
</style>
